import * as React from "react";
import { HeadFC } from "gatsby";
import "../../styles/main.css";
import emailjs from "@emailjs/browser";
import { Button, Typography } from "@mui/material";
import { gql, useQuery } from "@apollo/client";

type Props = {
  request_appraisals?: boolean;
};

const form_style = {
  display: "flex",
  flexDirection: "column" as const,
};

const form_line_style = {
  width: "100%",
  display: "flex",
  flexDirection: "row" as const,
  marginBottom: 20,
};

const form_input_style = {
  width: 400,
  height: 32,
  fontSize: 18,
  fontFamily: "Ubuntu",
};

const form_label_style = {
  display: "block",
  width: 150,
  minWidth: 150,
};

const form_container_style = {
  marginTop: 40,
};

const RequestAppraisalsPage = (props: Props) => {
  const {
    data: me_data,
  } = useQuery(
    gql`
      query {
        me {
          id
          first_name
          last_name
          email
        }
      }
    `,
    {
      fetchPolicy: "cache-first",
    }
  );

  function sendEmail(e: React.FormEvent<HTMLFormElement>) {
    emailjs
      .sendForm(
        "service_tqw1fgb",
        "template_tl9g967",
        e.target as HTMLFormElement,
        "9ekwaqSyW2kpieqLH"
      )
      .then(
        (result) => {
          console.log("SUCCESS!", result.text);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
    document.getElementById("contact-form-wrapper")?.setAttribute("hidden", "");
    document
      .getElementById("form-submission-thank-you")
      ?.removeAttribute("hidden");
  }

  function handle_submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    sendEmail(e);
  }

  return (
    <>
      <Typography variant={"h2"}>{`Contact Us${props.request_appraisals ? ` - Request Appraisals` : ""
        }`}</Typography>
      <p style={{ fontSize: 16 }}>
        {props.request_appraisals
          ? `Let us know if you're interested in purchasing more appraisals. We'll get back to you within the next 24-48 hours!`
          : `Reach out to us with any questions or feedback! We'd love to hear from you.`}
      </p>
      <div style={form_container_style}>
        <form onSubmit={handle_submit}>
          <div style={form_style}>
            <div style={form_line_style}>
              <div>
                <label htmlFor="name" style={form_label_style}>
                  Name
                </label>
              </div>
              <input
                style={form_input_style}
                type="text"
                id="name"
                name="name"
                required
                defaultValue={
                  me_data?.me
                    ? `${me_data.me.first_name} ${me_data.me.last_name}`
                    : undefined
                }
              />
            </div>
            <div style={form_line_style}>
              <label htmlFor="name" style={form_label_style}>
                Email
              </label>
              <input
                style={form_input_style}
                type="email"
                id="email"
                name="email"
                required
                defaultValue={me_data?.me?.email ?? undefined}
              />
            </div>
            <div style={form_line_style}>
              <label htmlFor="subject" style={form_label_style}>
                Subject
              </label>
              <input
                style={form_input_style}
                type="text"
                id="subject"
                name="subject"
                required
                defaultValue={
                  props.request_appraisals ? "Requesting BitLot Appraisals" : ""
                }
              />
            </div>
            <div style={form_line_style}>
              <label htmlFor="message" style={form_label_style}>
                Message
              </label>
              <textarea
                id="message"
                name="message"
                style={{ width: "80%", height: 200 }}
                required
                defaultValue={
                  props.request_appraisals
                    ? "I would like to request more BitLot Appraisals!"
                    : ""
                }
              ></textarea>
            </div>
            <Button
              type={"submit"}
              variant={"contained"}
              sx={{ my: 2, display: "block", color: "white", width: 80 }}
            >
              {"Submit"}
            </Button>
          </div>
        </form>
      </div>
      <div hidden id="form-submission-thank-you">
        <h1>Thank you for reaching out! We&apos;ll be in touch.</h1>
      </div>
    </>
  );
};

export default RequestAppraisalsPage;

export const Head: HeadFC = () => <title>Contact Us - BitLot.app</title>;
